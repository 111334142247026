<template>
  <div class="wrapper proposals">
    <h1>Mis propuestas</h1>
    <p>Aqui se listan todas las propuestas que hayas recordado despues de abrirlas mediante el link que te hicimos llegar a tu correo.</p>
    <div class="proposals-list">
      <div v-for="(item, idx) in proposalsList" :key="idx" @click="goToProposal(item.pid)">
        {{ item.desc }}
      </div>
    </div>
    <a href="#" @click="deleteProposals()" class="delete-proposals" v-if="proposalsList">dejar de recordar todas mis propuestas</a>
  </div>
</template>
<style scoped>
  .navbar.scrolled{
    background: #eae9de;
  }

</style>
<script>
import firebase from "../fbConfig";
const proposalsDb = firebase.ref("/proposals");

export default {
  name: 'Proposals',
  metaInfo() {
    return {
      title: 'Proposals'
    }
  },
  components: {
  },
  data: () => {
    return {
      proposalsList: []
    }
  },
  methods: {
    goToProposal(pid){
      this.$router.push({ name: 'Proposal', params: { id: pid } })
      
    },
    readLocalStorage(){
      const userProjects = localStorage.getItem('userProjects')
      this.proposalsList = JSON.parse(userProjects)
    },
    deleteProposals(){
      localStorage.removeItem('userProjects')
      this.proposalsList = null
    }
  },
  mounted () {
    this.readLocalStorage();
  }
}
</script>