import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    notification: {
      show: false,
      message: 'default notification',
      type: 'success'
    }
  },
  actions: {
    notify ({ commit }, data) {
      commit('toggleNotification', data)
      setTimeout(() => { commit('resetNotification') }, 5000);
    },
    sendContactEmail ({ dispatch, commit }, data) {
      commit('toggleLoader', true)
      axios.post('https://apis.webrocket.services/v1/email/contact', data).then((response: any) => {
          dispatch('notify', {show: true, message: 'Tu mensaje ha sido enviado.', type: 'success'})
        }).catch((error: any) => {
          dispatch('notify', {show: true, message: 'Hubo un error al enviar tu mensaje.', type: 'danger'})
          console.log(error)
        }).finally(() => {
          commit('toggleLoader', false)
        })
    }
  },
  mutations: {
    toggleLoader (state, val) {
      state.isLoading = val
    },
    toggleNotification (state, val) {
      state.notification = val
    },
    resetNotification (state, val) {
      state.notification = {
        show: false,
        message: 'default notification',
        type: 'success'
      }
    }
  },
  modules: {
  }
})
