<template>
  <div class="home">
    <SectionHeader :webConfig="webConfig" />
    <SectionServices :webConfig="webConfig" />
    <SectionHighlights :webConfig="webConfig" />
    <SectionPortfolio :webConfig="webConfig" />
    <SectionContact :webConfig="webConfig" />
  </div>
</template>
<script>
// @ is an alias to /src
import SectionHeader from '@/components/SectionHeader.vue'
import SectionServices from '@/components/SectionServices.vue'
import SectionHighlights from '@/components/SectionHighlights.vue'
import SectionContact from '@/components/SectionContact.vue'
import SectionPortfolio from '@/components/SectionPortfolio.vue'

export default {
  name: 'Home',
  components: {
    SectionHeader,
    SectionServices,
    SectionHighlights,
    SectionContact,
    SectionPortfolio,
  },
  data: () => {
    return {
      webConfig: {
        title: "hello"
      }
    }
  }
}
</script>
