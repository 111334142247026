<template>
  <div class="wrapper proposals">
    <div v-if="proposal" class="content">
      <h1>{{proposal.title}} </h1>
      <h2>{{proposal.company.name}} </h2>
      <h3>{{proposal.displayDate}} {{proposal.version}}</h3>
      <div v-if="isAuthorized" class="proposal-data">

    <div class="row" v-if="index['summary'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['summary']['indexId']}}. {{index['summary']['name']}}.</h3>
        <p class="ml-4" v-html="proposal.summary.issue"></p>
        <p class="ml-4" v-html="proposal.summary.solution"></p>
      </div>
    </div>

    <div class="row" v-if="index['modules'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['modules']['indexId']}}. {{index['modules']['name']}}.</h3>
        <div v-for="(item, indexts) in proposal.modules" :key="indexts" class="ml-4">
          <h5>{{index['modules']['indexId']}}.{{ indexts+1 }} {{ item.title }}</h5>
          <p v-if="item.description != ''" v-html="item.description"></p>
          <div v-for="(subItem, idx) in item.subModules" :key="`${indexts}-${idx}`" class="ml-4">
            <p>
              <strong>
              {{index['modules']['indexId']}}.{{ indexts+1 }}.{{ idx+1 }} {{subItem.title}}
              </strong>
              <label class="d-block ml-4" v-html="subItem.description"></label>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="index['sections'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['sections']['indexId']}}. {{index['sections']['name']}}.</h3>
        <p class="ml-4" v-html="proposal.sections.description"></p>
        <p v-for="(item, idx) in proposal.sections.list" :key="idx" class="ml-4">            
          <strong class="ml-4 d-block">
          {{index['sections']['indexId']}}.{{idx+1}}. {{item.title}}
          </strong>
          <label class="ml-4 d-block" v-html="item.description"></label>
        </p>
      </div>
    </div>

    <div class="row" v-if="index['techs'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['techs']['indexId']}}. {{index['techs']['name']}}.</h3>
        <p class="ml-4" v-html="proposal.techs.description"></p>
        <p v-for="(item, idx) in proposal.techs.list" :key="idx" class="ml-4">
          <strong class="ml-4">
            {{item.title}} 
          </strong>
          <label v-html="item.description"></label>
        </p>
      </div>
    </div>

    <div class="row" v-if="index['deliverables'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['deliverables']['indexId']}}. {{index['deliverables']['name']}}.</h3>
        <p class="ml-4" v-html="proposal.deliverables.description"></p>
        <div v-for="(item, indexs) in proposal.deliverables.subsections" :key="indexs" class="ml-4">
          <h5>{{index['deliverables']['indexId']}}.{{ indexs+1 }}. {{ item.title }}</h5>
          <p class="ml-4 mb-0" v-html="item.description"></p>
          <p class="ml-4 mt-0">
            <strong v-for="(subItem, idx) in item.list" :key="`${indexs}-${idx}`" class="ml-4 d-block">
            - {{subItem.title}}
            </strong>
          </p>
        </div>
      </div>
    </div>

    <div class="row" v-if="index['cost'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['cost']['indexId']}}. {{index['cost']['name']}}.</h3>
        <p class="ml-4" v-html="proposal.cost.description"></p>
        <p class="ml-4" v-if="proposal.cost.details" v-html="proposal.cost.details"></p>
        <p class="ml-4" v-if="proposal.cost.terms" v-html="proposal.cost.terms"></p>
        <p id="price-list" class="ml-4">            
          <span v-for="(item, idx) in proposal.cost.concepts" :key="idx" class="ml-4 d-block">
            <strong class="d-block price-concept">
            {{item.concept}}
            </strong>
            <label class="ml-4 d-block my-0 price-desc" v-if="item.description != ''">
            <small>{{item.description}}</small>
            </label>
            <label class="price-tag" v-if="item.price != ''">
              ${{parseFloat(item.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}
            </label>
          </span>
          <span class="ml-4 d-block">
            <strong class="d-block">
              Total
            </strong>
            <label class="price-tag bg-gray" v-if="proposal.cost.total != undefined && proposal.cost.total != NaN">
              ${{parseFloat((proposal.cost.total)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}}
            </label>
          </span>
        </p>
      </div>
    </div>

    <div class="row" v-if="index['guarantee'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['guarantee']['indexId']}}. {{index['guarantee']['name']}}.</h3>
        <p class="ml-4" v-html="proposal.guarantee.description"></p>
        <p class="ml-4" v-html="proposal.guarantee.highlight"></p>
        <p class="ml-4">            
          <strong v-for="(item, idx) in proposal.guarantee.list" :key="idx" class="ml-4 d-block">
          - {{item.title}}
          </strong>
        </p>
      </div>
    </div>

    <div class="row" v-if="index['terms'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
        <h3>{{index['terms']['indexId']}}. {{index['terms']['name']}}.</h3>
        <p class="ml-4" v-html="proposal.terms.description"></p>
        <p class="ml-4">            
          <span v-for="(item, idx) in proposal.terms.list" :key="idx" class="ml-4 d-block">
          - <strong>
            {{item.title}}: 
            </strong>
            <p v-html="item.description"></p>
          </span>
        </p>
      </div>
    </div>
    <div class="row" v-if="index['terms'] != undefined">
      <div class="col-md-10 col-sm-12 mx-auto">
      </div>
    </div>


<!--         comienzo pin        comienzo pin -->
      </div>

      <div v-else>
        <div class="form">
          <div class="field">
            <label>PIN</label>
            <input type="text" name="name" v-model="userPin" placeholder="XXX-0000">
          </div>
          <div class="field">
            
              
              <input type="checkbox" name="rememberPin" id="remember-pin" v-model="rememberUserPin"> 
              <label for="remember-pin" class="checkbox">Recordar PIN</label>
          </div>
          <div class="field">
            <div class="error" v-if="incorrectPin">
              PIN Incorrecto
            </div>
            <button @click="validatePin()">Validar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .navbar.scrolled{
    background: #eae9de;
  }

</style>
<script>
import firebase from "../fbConfig";
const proposalsDb = firebase.ref("/proposals");

export default {
  name: 'Proposal',
  metaInfo() {
    return {
      title: 'Proposal'
    }
  },
  components: {
  },
  data: () => {
    return {
      userPin: null,
      incorrectPin: false,
      isAuthorized: false,
      rememberUserPin: false,
      index: {},
      proposal: null
    }
  },
  methods: {
    createIndex() {
      let i = 0


      if(this.proposal['summary'] !== undefined)
        this.index['summary'] = {name: 'Resumen Ejecutivo', indexId: ++i}

      if(this.proposal['modules'] !== undefined)
        this.index['modules'] = {name: 'Alcance de desarrollo', indexId: ++i}

      if(this.proposal['sections'] !== undefined)
        this.index['sections'] = {name: 'Otras Funcionalidades', indexId: ++i}

      if(this.proposal['techs'] !== undefined)
        this.index['techs'] = {name: 'Tecnologías de desarrollo', indexId: ++i}

      if(this.proposal['deliverables'] !== undefined)
        this.index['deliverables'] = {name: 'Entregables', indexId: ++i}

      if(this.proposal['cost'] !== undefined)
        this.index['cost'] = {name: 'Costos', indexId: ++i}

      if(this.proposal['guarantee'] !== undefined)
        this.index['guarantee'] = {name: 'Duración del proyecto y garantía', indexId: ++i}

      if(this.proposal['terms'] !== undefined)
        this.index['terms'] = {name: 'Términos y condiciones', indexId: ++i}
    },
    validatePin() {
       if(this.userPin && this.userPin != '' && this.userPin.trim() ==this.proposal.config.password){
         this.isAuthorized = true
         this.incorrectPin = false
         if(this.rememberUserPin){
          let currentSavedProjects = JSON.parse(localStorage.getItem('userProjects'))
          const newProject = {pid: this.$route.params.id, pin: this.userPin, desc: `${this.proposal.company.name} | ${this.proposal.title} | ${this.proposal.displayDate} | ${this.proposal.version}`}
          if(currentSavedProjects){
            if(currentSavedProjects.length ){
              const newProjectExist = currentSavedProjects.filter(x=> x.pid === this.$route.params.id)
              if(!newProjectExist.length)
                currentSavedProjects.push(newProject)
            }else{
              currentSavedProjects = [newProject]
            }
          }else{
            currentSavedProjects = [newProject]
          }
          localStorage.setItem('userProjects', JSON.stringify(currentSavedProjects));
         }
       }else{
         this.isAuthorized = false
         this.incorrectPin = true
       }
     },
     async getData(followUpId) {
       await proposalsDb.child(followUpId).once('value').then(snapshot => {
         this.proposal = snapshot.val()[0]
         this.proposal.cost.concepts
         this.createIndex()
         this.proposal.cost['total'] = 0;
         for (let elem of Object.values(this.proposal.cost.concepts)) {
           if(elem.price != '')
             this.proposal.cost['total'] += parseFloat(elem.price)
         }
         if(localStorage.getItem('userProjects')){
          const userProjects = JSON.parse(localStorage.getItem('userProjects'))
          const thisSaveProject = userProjects.filter( item => item.pid === this.$route.params.id)
          this.userPin = thisSaveProject.length ? thisSaveProject[0].pin : 0
          this.rememberUserPin = true
          this.validatePin()
         }
       })
    }
  },
  mounted () {
    document.title = `WRS - Propuesta ${this.$route.params.id}`
    this.getData(this.$route.params.id)
  }
}
</script>

