












import { Component, Prop, Vue } from 'vue-property-decorator';
import { Translator } from 'vue-google-translate';

@Component({
  components: {
    Translator
  }
})
export default class TheFooter extends Vue {
  @Prop() footerConfig!: any;
  translatorCountries = [
    {
      code: 'en|en',
      title: 'English',
    },
    {
      code: 'en|es',
      title: 'Spanish',
    }
  ]
}
