























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TheNavBar extends Vue {
  @Prop() private headerConfig!: string;
  menuOpen = false;
  toggleMenu () {
    document.querySelector('.menu')!.classList.add('mobile')
    this.menuOpen = !this.menuOpen;
    if(this.menuOpen)
      document.querySelector('.menu')!.classList.add('show')
    else
      document.querySelector('.menu')!.classList.remove('show')
  }
}
