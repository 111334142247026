import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import './assets/styles/reset.scss';
import './assets/styles/main.scss';
import router from './router/index'
import 'animate.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
