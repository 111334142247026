




















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SectionHighlights extends Vue {
  @Prop() private headerConfig!: string;
  title = "hello";
}
