import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Proposals from '../views/Proposals.vue'
import Proposal from '../views/Proposal.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    meta: {
      title: 'WRS - Inicio'
    }
  },
  {
    path: '/proposals',
    component: Proposals,
    name: 'Proposals',
    props: true,
    meta: {
      title: 'WRS - Propuestas'
    }
  },
  {
    path: '/proposals/:id',
    component: Proposal,
    name: 'Proposal',
    props: true
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
