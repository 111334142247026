

















import { Component, Prop, Vue } from 'vue-property-decorator';
// import { initBgDots } from '../assets/plugins/dots-bg/dots-bg.js'

@Component
export default class SectionHighlights extends Vue {
  @Prop() private headerConfig!: string;
  title = "hello";
  
  // mounted () {
  //   initBgDots()
  //   console.log(`the component is now mounted.`)
  // }
  
}
