<script>

export default {
  name: 'SectionPortfolio',
  data: () => {
    return {
      projectsList: [
        {
          img: {
            alt: 'Followup por web rocket services',
            name: 'followup.png',
          },
          title: 'Proyecto Followup by WRS',
          description:
            'Plataforma para el manejo de proyectos y seguimiento de propuestas para nuestros clientes en Web Rocket Services.',
          techSpecs: ['Sistema Web.', 'Base de datos JSON.', 'ReactJS & Firebase'],
          projectUrl: 'https://followup.webrocket.services/',
          status: 'Finalizada',
        },
        {
          img: {
            alt: 'Zarate seguros web rocket services',
            name: 'zarate-seguros.png',
          },
          title: 'Proyecto Zarate Seguros por web rocket services',
          description: 'Website informativo para clientes de aseguradoros.',
          techSpecs: ['Página Web.', 'VueJS 3'],
          projectUrl: 'https://webrocketservices.web.app/',
          status: 'Sin Finalizar',
        },
        {
          img: {
            alt: 'erent proyecto web por web rocket services',
            name: 'erent.jpg',
          },
          title: 'Proyecto ERENT',
          description:
            'Website para negocio de renta de maquinaria. Con contenido dinámico para listar y rentar su equipo.',
          techSpecs: [
            'Página Web.',
            'REST API.',
            'Base de datos Relacional.',
            'Vue2, Python & MySQL',
          ],
          projectUrl: 'https://erent.mx/',
          status: 'Sin Finalizar',
        },
        {
          img: {
            alt: 'Milactivos por web rocket services',
            name: 'milactivos.jpg',
          },
          title: 'Proyecto milactivos.',
          description:
            'Sistema web de gestion de flujos de dinero, entradas, salidas, cuentas, estadisticas, etc.',
          techSpecs: [
            'Página Web.',
            'Sistema Web.',
            'REST API.',
            'Base de datos Relacional.',
            'Vue3, NodeJS & MySQL',
          ],
          projectUrl: '#',
          status: 'Pendiente',
        },
      ]
    }
  }
}

</script>


<template>
  <div class="wrapper portfolio bold-section" id="portfolio">
    <h1>Portafolio</h1>
    
    <div class="description">
      <div class="portfolio-list">
        <div class="portfolio-item" v-for="(item, index) in projectsList" :key="index">
          <img :src="require(`@/assets/images/portfolio/${item.img.name}`)" :alt="item.img.alt">
          <div class="item-description">
            <h2>{{ item.title }}</h2>
            <p>{{item.description}}</p>
            <br>
            <h2>Tech Specs:</h2>
            <ul>
              <li v-for="(i, idx) in item.techSpecs" :key="idx">- {{ i }}.</li>
            </ul>
            <div class="link">

              <a :href="item.projectUrl" target="_blank">Ver sitio</a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
