<template>
  <div id="app">
    <TheLoader v-if="$store.state.isLoading"/>
    <TheNavBar :headerConfig="webConfig.navBar"/>
    <router-view/>
    <TheFooter :footerConfig="webConfig.footer"/>
    <TheNotification v-if="$store.state.notification.show"/>
  </div>
</template>
<script>
function isPWA() {
  return window && window.matchMedia("(display-mode: standalone)").matches;
}
import TheFooter from '@/components/TheFooter.vue'
import TheNavBar from '@/components/TheNavBar.vue'
import TheLoader from '@/components/TheLoader.vue'
import TheNotification from '@/components/TheNotification.vue'
import firebase from "./fbConfig";

export default {
  name: 'Home',
  metaInfo: {
    changed(metaInfo) {
      firebase.analytics().setCurrentScreen(metaInfo.title);
      firebase.analytics().logEvent("page_view");
      firebase.analytics().logEvent("screen_view", {
        app_name: isPWA() ? "pwa" : "web",
        screen_name: metaInfo.title,
        app_version: 1.0
      });
    }
  },
  components: {
    TheNotification,
    TheLoader,
    TheNavBar,
    TheFooter
  },
  data: () => {
    const currentDate = new Date().getFullYear()
    return {

      webConfig: {
        navBar: {
          title: "WebRocket Services"
        },
        header: {
          title: "WebRocket Services"
        },
        footer: {
          copyright: `Web Rocket Services ${currentDate}`
        },
      }
    }
  },
  methods: {
    handleScroll () {
      let scrollY = window.scrollY
      this.checkScrollAnimation(scrollY)
    },
    checkScrollAnimation (scrollY) {
      let ratio = 550
      let servicesPos = document.getElementById('services').offsetTop - ratio
      let aboutPos = document.getElementById('about').offsetTop - ratio
      let contactPos = document.getElementById('contact').offsetTop - ratio

      if (scrollY > 550) {
        document.getElementById('navbar').classList.add('scrolled')
      } else {
        document.getElementById('navbar').classList.remove('scrolled')
      }

      let prefix = 'animate__'    
      if (scrollY > servicesPos && scrollY < aboutPos) {
        document.querySelectorAll('.services-icon').forEach(elem => {
          elem.classList.add(`${prefix}animated`, `${prefix}flipInX`)
        });
        
      }else{
        document.querySelectorAll('.services-icon').forEach(elem => {
          elem.classList.remove(`${prefix}animated`, `${prefix}flipInX`)
        });
      }

      if (scrollY > aboutPos && scrollY < contactPos) {
        document.querySelectorAll('.about-icon').forEach(elem => {
          elem.classList.add(`${prefix}animated`, `${prefix}bounceInLeft`)
        });
      }else{
        document.querySelectorAll('.about-icon').forEach(elem => {
          elem.classList.remove(`${prefix}animated`, `${prefix}bounceInLeft`)
        });
      }

      if (scrollY > contactPos) {
        document.querySelectorAll('.contact-icon').forEach(elem => {
          elem.classList.add(`${prefix}animated`, `${prefix}fadeInUpBig`)
        });
      }else{
        document.querySelectorAll('.contact-icon').forEach(elem => {
          elem.classList.remove(`${prefix}animated`, `${prefix}fadeInUpBig`)
        });
      }
    },

  },
  mounted() {
    if(this.$route.name === 'Home'){ 
      this.checkScrollAnimation(window.scrollY)
      window.addEventListener("scroll", this.handleScroll)
    } else {
      document.getElementById('navbar').classList.add('scrolled')
    }
  }
}
</script>

