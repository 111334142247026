








import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TheNotification extends Vue {
}
