























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SectionContact extends Vue {
  @Prop() private headerConfig!: string;
  title = "hello";
  emailContent = {
    name: '',
    phone: '',
    description: ''
  }
  sendEmail () {
    if (this.emailContent.name === '' && this.emailContent.phone === '' && this.emailContent.description === ''){
      this.$store.dispatch('notify', {show: true, message: 'Llena todos los datos.', type: 'warning'})
      return false
    }
    const params = new URLSearchParams()
    params.append('name', this.emailContent.name)
    params.append('phone', this.emailContent.phone)
    params.append('description', this.emailContent.description)

    this.$store.dispatch('sendContactEmail', params)
    this.emailContent = {
      name: '',
      phone: '',
      description: ''
    }
  }
}
